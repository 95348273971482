import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Input } from "./Form.js";
import Icon from "./Icon.js";

function SearchBar({
   containerClassName = "",
   className = "",
   onChange,
   initialQuery = "",
   hide = false,
   ...rest
}) {
   const location = useLocation();
   const [text, setText] = useState(initialQuery);
   return (
      <div
         className={`relative animate-ease-3 overflow-hidden
            ${containerClassName}
            ${hide ? "h-0 mt-0" : "h-8"}
         `}
      >
         <Input
            value={text}
            onChange={e => {
               let text = e.target.value;
               setText(text);
               if (onChange) onChange(text);
            }}
            className={`
               ${className} animate-ease-3 cursor-pointer w-full px-4
               ${hide ? "h-0" : "h-8"}
               `}
            style={{ minWidth: 120 }}
            {...rest}
            autoCorrect="none"
            autoCapitalize="none"
            spellCheck="none"
         />
         <div
            className={`absolute right-0 px-2 top-0 h-full items-center cursor-pointer animate-ease-2 text-gray-600 hover:text-gray-900 active:text-gray-800
               ${
                  text.trim() === ""
                     ? "opacity-0 invisible"
                     : "opacity-100 visible"
               }
               ${hide ? "hidden" : "flex"}
               `}
            onClick={_ => {
               setText("");
               onChange("");
            }}
         >
            <Icon icon="close" size={12} />
         </div>
      </div>
   );
}

export default SearchBar;
