import { gql } from "@apollo/client";

export const FETCH_PRODUCTION_PLANS = gql`
  query FETCH_PRODUCTION_PLANS($statesIn: [String]) {
    me {
      id
      productionPlans(statesIn: $statesIn) {
        id
        name
        totalQty
        totalCbm
        state
        startDate
        readyDate
      }
    }
  }
`;

export const PRODUCTION_PLAN_FRAGMENT = gql`
  fragment productionPlan on ProductionPlanType {
    id
    name
    totalQty
    totalCbm
    state
    startDate
    readyDate
    lines {
      id
      product {
        id
        number
        name
        odooId
        itemsPerSet
        cbm: outerCartonCbm
        images: computedImages(size: "300x300") {
          id
          url
        }
        productLine {
          id
          name
          slug
        }
      }
      qty
      totalCbm
      readyDate
    }
  }
`;

export const CREATE_PRODUCTION_PLAN = gql`
  mutation CREATE_PRODUCTION_PLAN(
    $parentId: ID
    $name: String!
    $customer: String
    $planType: String
    $orderType: String
    $startDate: DateTime
    $autoSendToOdoo: Boolean
    $createdByCustomer: Boolean
  ) {
    createProductionPlan(
      parentId: $parentId
      name: $name
      customer: $customer
      planType: $planType
      orderType: $orderType
      startDate: $startDate
      autoSendToOdoo: $autoSendToOdoo
      createdByCustomer: $createdByCustomer
    ) {
      productionPlan {
        id
      }
    }
  }
`;

export const FETCH_PRODUCTION_PLAN = gql`
  query FETCH_PRODUCTION_PLAN($id: ID!) {
    productionPlan(id: $id) {
      ...productionPlan
    }
  }
  ${PRODUCTION_PLAN_FRAGMENT}
`;

export const SAVE_PRODUCTION_PLAN = gql`
  mutation SAVE_PRODUCTION_PLAN(
    $id: ID!
    $startDate: DateTime
    $lines: [ProductionPlanLineInputType]
  ) {
    saveProductionPlan(id: $id, startDate: $startDate, lines: $lines) {
      productionPlan {
        ...productionPlan
      }
    }
  }
  ${PRODUCTION_PLAN_FRAGMENT}
`;

export const COMPUTE_PRODUCTION_PLAN = gql`
  mutation COMPUTE_PRODUCTION_PLAN($id: ID!) {
    computeProductionPlan(id: $id) {
      productionPlan {
        ...productionPlan
      }
    }
  }
  ${PRODUCTION_PLAN_FRAGMENT}
`;

export const CANCEL_PRODUCTION_PLAN = gql`
  mutation CANCEL_PRODUCTION_PLAN($planId: ID!, $cancelLaterPlans: Boolean!) {
    cancelProductionPlan(planId: $planId, cancelLaterPlans: $cancelLaterPlans) {
      productionPlan {
        id
        state
      }
    }
  }
`;
