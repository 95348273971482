import Button from "components/shared/Button";
import { Input } from "components/shared/Form";
import { useModals } from "components/shared/ModalProvider";
import {
  BsCalendar3Week,
  BsExclamationTriangleFill,
  BsTrash
} from "react-icons/bs";
import ChooseProduceView from "./ChooseProduceView";
import moment from "moment";
import { Link } from "react-router-dom";
import ContactView from "./ContactView";
import { useContext } from "react";
import { AppContext } from "App";

const ProductsView = ({
  originalPlan,
  lines,
  setLines,
  startDate,
  setStartDate,
  requiredRecompute,
  setRequiredRecompute,
  computeHandler,
  isSaving,
  isComputing
}) => {
  const modal = useModals();
  const contactModal = useModals();
  const { user } = useContext(AppContext);

  function addProduct() {
    modal.present({
      title: "Add Product",
      maxWidth: "max-w-7xl",
      children: (
        <ChooseProduceView
          onSelect={product => {
            const sets = 1;
            const totalCbm = product.cbm * sets;
            setLines([...lines, { product, qty: sets, totalCbm }]);
            modal.hide();
            setRequiredRecompute(true);
          }}
        />
      )
    });
  }

  function getInTouchHandler() {
    contactModal.present({
      title: "Get in Touch",
      subtitle:
        "Please type your message below and we will get back to you as soon as possible.",
      children: <ContactView hide={contactModal.hide} email={user.email} />,
      center: true,
      maxWidth: "max-w-4xl"
    });
  }

  return (
    <div className="card h-full flex flex-col">
      <div>
        <h4>Start Date</h4>
        <div>
          <label className="pr-3">Choose Order Start Date: </label>
          <input
            type="date"
            className="text-blue-600 font-semibold border bg-blue-100 px-4 py-1 rounded-full"
            value={startDate}
            onChange={e => {
              setStartDate(e.target.value);
              setRequiredRecompute(true);
            }}
          />
        </div>
        <div className="mt-1 text-xs opacity-70">
          The start date is based on production starting date according to
          payment terms
        </div>
      </div>

      <hr className="my-6" />

      <h4>Products</h4>

      {lines.length === 0 ? (
        <div className="py-4 mt-2 border-y opacity-70">
          Your order is empty. Please add some products.
        </div>
      ) : (
        <div className="mt-2 -mx-2">
          <table>
            <thead className=" whitespace-nowrap">
              <tr>
                <th className="text-left w-full">Item Description</th>
                <th className="text-right">QTY / Carton</th>
                <th className="text-right">Carton QTY</th>
                <th className="text-right">Total QTY</th>
                <th className="text-right">CBM</th>
                <th className="text-right">Ready Date</th>
              </tr>
            </thead>
            <tbody>
              {lines.length === 0 ? (
                <tr>
                  <td className="py-4 border-y opacity-70" colSpan={5}>
                    Your order is empty. Please add some products.
                  </td>
                </tr>
              ) : (
                lines.map((line, index) => {
                  return (
                    <tr key={index} className="border-y border-gray-100">
                      <td>
                        <div className="flex space-x-2 items-center">
                          <img
                            src={line.product.images[0]?.url}
                            alt={line.product.name}
                            className="w-16 h-16 object-contain"
                          />
                          <div>
                            <div>
                              <Link
                                className="text-blue-500 font-semibold"
                                target="_blank"
                                to={`/products/${
                                  line.product.productLine.slug
                                }`}
                              >
                                {line.product.productLine.name}
                              </Link>
                            </div>

                            <div className="flex items-center space-x-2">
                              <div>{line.product.number}</div>
                              <div>
                                <Button
                                  title="Remove Product"
                                  danger
                                  className="text-xs"
                                  onClick={() => {
                                    const newLines = [...lines];
                                    newLines.splice(index, 1);
                                    setLines(newLines);
                                    setRequiredRecompute(true);
                                  }}
                                >
                                  <BsTrash />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="text-right">{line.product.itemsPerSet}</td>

                      <td className="text-right">
                        <Input
                          disabled={originalPlan.state !== "DRAFT"}
                          className="text-right w-20"
                          value={line.qty}
                          placeholder="Input Quantity"
                          onChange={e => {
                            if (isNaN(e.target.value)) return;
                            const newLines = [...lines];
                            const sets = Number(e.target.value);
                            newLines[index].qty = sets;
                            newLines[index].totalCbm =
                              newLines[index].product.cbm * sets;
                            setLines(newLines);
                            setRequiredRecompute(true);
                          }}
                        />
                      </td>
                      <td className="text-right">
                        {line.qty * line.product.itemsPerSet}
                      </td>
                      <td className="text-right">
                        {line.totalCbm?.toFixed(3)}
                      </td>
                      <td className="text-right whitespace-nowrap">
                        {line.readyDate
                          ? moment(line.readyDate).format("YYYY-MM-DD")
                          : "-"}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>

            <tfoot>
              <tr className="text-lg font-semibold border-y whitespace-nowrap">
                <td className="px-2 py-2">Total</td>
                <td />
                <td className="text-right px-4">
                  {lines.reduce((acc, cur) => acc + cur.qty, 0)}
                </td>
                <td className="text-right px-4">
                  {lines.reduce(
                    (acc, cur) => acc + cur.qty * cur.product.itemsPerSet,
                    0
                  )}
                </td>
                <td className="text-right px-2">
                  {lines.reduce((acc, cur) => acc + cur.totalCbm, 0).toFixed(3)}
                </td>
                <td className="text-right px-2">
                  {originalPlan.readyDate
                    ? moment(originalPlan.readyDate).format("YYYY-MM-DD")
                    : "-"}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}

      {originalPlan.state === "DRAFT" && (
        <div className="flex justify-between items-start space-x-8 mt-4">
          <Button onClick={addProduct} primary className="text-xs">
            + Add Product
          </Button>

          <div className="flex flex-col space-y-3">
            <div className="text-xs opacity-70 text-right float-right">
              The actual ready date might be +/- 7 days. Since our inventory is
              dynamic, the estimated ready date might change anytime until you
              request to confirm.
            </div>
            {requiredRecompute && (
              <div className=" text-orange-600 font-semibold flex items-center space-x-2 justify-end">
                <BsExclamationTriangleFill />
                <span>
                  You have made changes to the order. Please click the button
                  below to recompute the ready date.
                </span>
              </div>
            )}
            {originalPlan.state === "DRAFT" && (
              <div className="flex justify-end">
                <Button
                  onClick={computeHandler}
                  loading={isComputing}
                  disabled={isSaving}
                  icon={<BsCalendar3Week />}
                >
                  Compute Ready Date
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex-1" />

      <div className="flex mt-8">
        <div className="font-semibold text-orange-600">
          For assistance with locating items or special requests, don't hesitate
          to{" "}
          <a
            className="font-semibold cursor-pointer text-blue-600 hover:text-blue-700 active:text-blue-800"
            href="mailto:jonatan@waboba.com?subject=[Waboba] Order Planner - Assistance Required"
          >
            Get in Touch
          </a>
          !
        </div>
      </div>
    </div>
  );
};

export default ProductsView;
