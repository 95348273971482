import Spinner from "components/shared/Spinner";
import Errors from "components/shared/Errors";
import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import ShipmentStatus from "components/shared/ShipmentStatus";
import SearchBar from "components/shared/SearchBar";
import { useModals } from "components/shared/ModalProvider";
import { Button } from "components/shared/base";
import ShipmentDetail from "./ShipmentDetail";
import moment from "moment";

const FETCH_CUSTOMER_SHIPMENTS = gql`
   query FETCH_CUSTOMER_SHIPMENTS(
      $limit: Int
      $offset: Int
      $latestStatusIn: [String]
      $query: String
   ) {
      customerShipments(
         limit: $limit
         offset: $offset
         latestStatusIn: $latestStatusIn
         query: $query
      ) {
         results {
            id
            invoiceNumber
            customer {
               id
               name
            }
            shipmentConsigneeName
            latestStatus
            requestCargoReadyDate
            latestEtd
            latestEta
         }
         total
      }
   }
`;

const PAGE_SIZE = 30;

const Shipments = () => {
   const [searchText, setSearchText] = useState("");
   const latestStatusIn = [
      "in production",
      "delivered to port",
      "shipping",
      "arrived at destination"
   ];
   const [offset, setOffset] = useState(0);
   const queryCustomerShipments = useQuery(FETCH_CUSTOMER_SHIPMENTS, {
      variables: { limit: PAGE_SIZE, offset, latestStatusIn, query: searchText }
   });

   return (
      <div className="space-y-6 p-10 bg-gray-100 flex-1 flex flex-col">
         <div className="container mx-auto max-w-7xl flex flex-col flex-1">
            <SearchBar
               onChange={setSearchText}
               placeholder="Search by Sales Order Number, PO or customer name."
            />

            <div className="bg-white rounded-2xl mt-4 relative flex-1">
               <ShipmentList
                  {...queryCustomerShipments}
                  offset={offset}
                  setOffset={setOffset}
               />
            </div>
         </div>
      </div>
   );
};

const ShipmentList = ({ data, loading, error, offset, setOffset }) => {
   const modal = useModals();

   if (loading) return <Spinner text="Loading Shipments..." />;
   if (error) return <Errors error={error} />;

   const { results, total } = data.customerShipments;

   return (
      <div>
         <table>
            <thead>
               <tr>
                  <th className="px-4 py-3 bg-gray-100 border-b z-10 border-gray-100 bg-opacity-70 backdrop-blur sticky top-0 text-left">
                     Shipment
                  </th>
                  <th className="px-4 py-3 bg-gray-100 border-b z-10 border-gray-100 bg-opacity-70 backdrop-blur sticky top-0 text-left">
                     Consignee
                  </th>
                  <th className="px-4 py-3 bg-gray-100 border-b z-10 border-gray-100 bg-opacity-70 backdrop-blur sticky top-0 text-right">
                     Cargo Ready Date
                  </th>
                  <th className="px-4 py-3 bg-gray-100 border-b z-10 border-gray-100 bg-opacity-70 backdrop-blur sticky top-0 text-right">
                     ETD
                  </th>
                  <th className="px-4 py-3 bg-gray-100 border-b z-10 border-gray-100 bg-opacity-70 backdrop-blur sticky top-0 text-right">
                     ETA
                  </th>
                  <th className="px-4 py-3 bg-gray-100 border-b z-10 border-gray-100 bg-opacity-70 backdrop-blur sticky top-0 text-right">
                     Status
                  </th>
               </tr>
            </thead>
            <tbody>
               {results.map(s => (
                  <tr
                     key={s.id}
                     className="cursor-pointer hover:bg-gray-50 active:bg-gray-100"
                     onClick={() =>
                        modal.present({
                           title: s.invoiceNumber,
                           maxWidth: "max-w-7xl",
                           children: <ShipmentDetail shipmentId={s.id} />
                        })
                     }
                  >
                     <td className="px-4 border-b border-gray-100">
                        <div>
                           <div>{s.invoiceNumber}</div>
                           <div className="text-xs opacity-70">
                              {s.customer.name}
                           </div>
                        </div>
                     </td>
                     <td className="px-4 border-b border-gray-100">
                        {s.shipmentConsigneeName}
                     </td>
                     <td className="px-4 border-b border-gray-100 text-right">
                        {s.requestCargoReadyDate
                           ? moment(s.requestCargoReadyDate * 1000).format(
                                "YYYY-MM-DD"
                             )
                           : "-"}
                     </td>
                     <td className="px-4 border-b border-gray-100 text-right">
                        {s.latestEtd}
                     </td>
                     <td className="px-4 border-b border-gray-100 text-right">
                        {s.latestEta}
                     </td>
                     <td className="px-4 border-b border-gray-100">
                        <div className="flex justify-end">
                           <ShipmentStatus status={s.latestStatus} />
                        </div>
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>

         <div className="p-6 flex justify-end space-x-2 opacity-60">
            {offset > 0 ? (
               <Button prev onClick={() => setOffset(offset - PAGE_SIZE)} />
            ) : null}
            <div>
               {offset + 1}-{PAGE_SIZE + offset} / {total}
            </div>
            {total > offset + PAGE_SIZE ? (
               <Button next onClick={() => setOffset(PAGE_SIZE + offset)} />
            ) : null}
         </div>
      </div>
   );
};

export default Shipments;
