import React from "react";
import PageTitle from "../../components/PageTitle";
import ContactForm from "components/forms/Contact";

export default () => (
  <div>
    <PageTitle title="Contact us" subtitle="We are here to listen." />

    <div className="container mx-auto max-w-md mt-8">
      <div className="text-center p-8">
        If you need any service or support, please send us a message and we will
        contact you as soon as possible.
      </div>

      <ContactForm />
    </div>
  </div>
);
