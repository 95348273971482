import Button from "components/shared/Button";
import { useModals } from "components/shared/ModalProvider";
import CreateOrderView from "./CreateOrderView";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FETCH_PRODUCTION_PLANS } from "./graphql";
import Spinner from "components/shared/Spinner";
import Errors from "components/Errors";
import moment from "moment";
import { useState } from "react";

const OrderList = () => {
  const [searchText, setSearchText] = useState("");
  const { loading, error, data } = useQuery(FETCH_PRODUCTION_PLANS, {
    variables: {
      statesIn: ["DRAFT", "PLANNING", "WAITING_TO_CONFIRM"],
    },
    fetchPolicy: "network-only",
  });
  const modal = useModals();

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const plans = data.me.productionPlans.filter((plan) => plan.name.toLowerCase().includes(searchText.toLowerCase()));

  function createOrderPlan() {
    modal.present({
      title: "Create Order Plan",
      children: <CreateOrderView />,
      center: true,
      maxWidth: "max-w-3xl",
    });
  }

  return (
    <div className="text-base m-10">
      <div className="">
        <h1>FOB Order Planner</h1>
        <div className="mt-2">Choose products and get the ready date instantly, from production to delivery.</div>
      </div>

      <div className="mt-8 card" style={{ minHeight: 800 }}>
        <div className="flex items-center space-x-6">
          <Button onClick={createOrderPlan}>+ Create Order Plan</Button>

          {/* <div className="flex-1">
            <SearchBar className="bg-gray-50" value={searchText} onChange={setSearchText} placeholder="Search order by name or reference." />
          </div> */}
        </div>

        <div className="mt-4 -mx-2">
          <table className="whitespace-nowrap">
            <thead>
              <tr className="border-y">
                <th className="w-1/2">Order</th>
                <th className="text-right">Quantity</th>
                <th className="text-right">CBM</th>
                <th className="text-right">Start Date</th>
                <th className="text-right">Ready Date</th>
              </tr>
            </thead>
            <tbody>
              {plans.length === 0 ? (
                <tr>
                  <td className="col-span-full opacity-70">
                    {searchText === "" ? (
                      "You have no order plan yet. Please create one."
                    ) : (
                      <span>
                        No order plan found for <strong>{searchText}</strong>.
                      </span>
                    )}
                  </td>
                </tr>
              ) : (
                plans.map((plan, index) => (
                  <tr key={index} className="border-t border-gray-100">
                    <td>
                      <Link className="text-sky-600 font-semibold py-1 block" to={plan.id}>
                        {plan.name}
                      </Link>
                    </td>

                    <td className="text-right">{plan.totalQty}</td>
                    <td className="text-right">{plan.totalCbm.toFixed(3)}</td>
                    <td className="text-right">{moment(plan.startDate).format("YYYY-MM-DD")}</td>
                    <td className="text-right">{plan.readyDate ? moment(plan.readyDate).format("YYYY-MM-DD") : "-"}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
