import { useContext, useState } from "react";
import { LOGIN_WITH_CODE, REQUEST_LOGIN_CODE } from "./graphql";
import { useMutation } from "@apollo/client";
import { Alert } from "components/shared/Toast";
import { Input } from "components/shared/Form";
import { AppContext } from "App";
import { Link, useNavigate } from "react-router-dom";
import { useModals } from "components/shared/ModalProvider";
import ContactForm from "components/forms/Contact";
import Button from "components/shared/Button";
import { MdEmail, MdPassword } from "react-icons/md";
import { IoBookOutline } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";

const LoginWithCodeView = () => {
   const { setUser } = useContext(AppContext);
   const [email, setEmail] = useState("");
   const [code, setCode] = useState("");
   const [hasRequested, setHasRequested] = useState(false);
   const [requestLoginCode, requestLoginCodeRes] = useMutation(
      REQUEST_LOGIN_CODE,
      {
         variables: { email },
         onCompleted() {
            setHasRequested(true);
            Alert(
               "success",
               "We have received your request, you will receive an email to get the Code to login."
            );
         },
         onError(error) {
            Alert("error", error.message);
         }
      }
   );
   const navigate = useNavigate();

   const [checkedTerms, setCheckedTerms] = useState(false);
   const [loginWithCode, loginWithCodeRes] = useMutation(LOGIN_WITH_CODE, {
      variables: { email, code },
      onCompleted({ loginWithCode }) {
         Alert("success", "You have successfully logged in.");
         setUser(loginWithCode.me);
         navigate("/landing");
      },
      onError(error) {
         Alert("error", error.message);
      }
   });

   const contactModal = useModals();

   function submit(e) {
      e.preventDefault();
   }

   function requestLoginCodeHandler() {
      if (!email) return Alert("error", "Please provide your email.");
      requestLoginCode();
   }

   return (
      <div>
         <div>
            <form className="space-y-3" onSubmit={submit}>
               <div className="text-center text-xs opacity-70">
                  Input your email address for your Waboba account to receive a
                  code to login.
               </div>
               <Input
                  className="w-full text-center bg-gray-50"
                  autoFocus
                  placeholder="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
               />

               <div className="flex items-baseline leading-normal space-x-2 pr-2">
                  <input
                     type="checkbox"
                     id="checkbox-terms"
                     className=" translate-y-[2px]"
                     checked={checkedTerms}
                     onChange={e => setCheckedTerms(e.target.checked)}
                  />
                  <label
                     className="text-xs block whitespace-normal cursor-pointer"
                     htmlFor="checkbox-terms"
                  >
                     <span className="font-semibold">
                        I have read and agree to the Terms of Use and Privacy
                        Policy.
                     </span>
                     <br />
                     <span className="text-gray-600">
                        By checking this box, I acknowledge that I have read and
                        understood the{" "}
                        <Link
                           className="text-sky-600 hover:text-sky-700 active:text-sky-800 underline"
                           to="/terms-privacy"
                           target="_blank"
                        >
                           Terms of Use and Privacy Policy
                        </Link>
                        , and I agree to be bound by them
                     </span>
                  </label>
               </div>

               {hasRequested ? (
                  <div className="text-center flex items-center flex-col">
                     <div className="text-xs opacity-70">
                        Please check your email for the code to login.
                     </div>
                     <Button
                        className="text-xs"
                        loading={requestLoginCodeRes.loading}
                        onClick={requestLoginCodeHandler}
                        type="button"
                     >
                        Request Code Again
                     </Button>
                  </div>
               ) : (
                  <div className="flex justify-center">
                     <Button
                        next
                        bold
                        loading={requestLoginCodeRes.loading}
                        disabled={!checkedTerms || !email}
                        onClick={requestLoginCodeHandler}
                        type="submit"
                     >
                        Next
                     </Button>
                  </div>
               )}

               {hasRequested ? (
                  <div className="space-y-4 flex flex-col items-center">
                     <Input
                        className="w-full text-center bg-gray-50"
                        placeholder="Code"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                     />
                     <Button
                        next
                        bold
                        loading={loginWithCodeRes.loading}
                        onClick={loginWithCode}
                        disabled={!code || !email || !checkedTerms}
                        type="submit"
                     >
                        Sign me In
                     </Button>
                  </div>
               ) : null}
            </form>

            <div className="text-sm font-semibold mt-6 border-y border-gray-700 border-opacity-10 py-2">
               <a
                  className="text-sky-600 hover:text-sky-700 flex items-center space-x-2"
                  href={
                     process.env.REACT_APP_AUTH_SERVER +
                     "?redirectTo=" +
                     encodeURIComponent(window.location.origin)
                  }
               >
                  <span>
                     <MdPassword />
                  </span>
                  <span>Login in with Password</span>
               </a>

               <Link
                  className="text-sky-600 hover:text-sky-700 flex space-x-2 items-center mt-2"
                  to="/register"
               >
                  <FaUserCircle />
                  <span>Sign Up</span>
               </Link>
            </div>
         </div>

         <div className="text-sm space-y-2 mt-4">
            <Link
               className=" text-sky-600 font-semibold flex items-center space-x-2"
               to="/public/catalog"
            >
               <span>
                  <IoBookOutline />
               </span>
               <span>Check out our new catalog</span>
            </Link>
            <Button
               onClick={() => {
                  contactModal.present({
                     title: "Contact Us",
                     center: true,
                     children: <ContactForm hide={contactModal.hide} />
                  });
               }}
               icon={<MdEmail size={14} />}
            >
               Contact us
            </Button>
         </div>
      </div>
   );
};

export default LoginWithCodeView;
